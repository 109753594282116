<template>
	<div class="DeviceSrvList">
		<div>
			<!--
			<el-button v-if="canShow()" :disabled="queryParams.ps.selectType==1" size="mini" type="primary" @click="changeQryType(1)">已选</el-button>
			<el-button v-if="canShow()"  :disabled="queryParams.ps.selectType==2" size="mini" type="primary" @click="changeQryType(2)">未选</el-button>
			<el-button v-if="canShow()"  :disabled="queryParams.ps.selectType==0" size="mini" type="primary" @click="changeQryType(0)">全部</el-button>
			-->
			<el-button v-if="canShow()" size="mini" type="primary" @click="doAddOrUpdateParam()">提交</el-button>
			<el-button size="mini" type="primary" @click="closeDevFunListDrawer()">关闭</el-button>
			<el-button size="mini" type="primary" @click="serviceCheck()">生成默认服务</el-button>
			<el-button size="mini" type="primary" @click="refresh()">查询</el-button>
		</div>
		
		<table v-if="plist && plist.length > 0" class="configItemTalbe" width="99%">
		    <thead>
				<tr><td>{{"name"|i18n}}</td>
				<td>{{'boardType'|i18n}}</td>
				<td>{{'boardName'|i18n}}</td>
				<td>{{'type'|i18n}}</td>
				<td>{{'status'|i18n}}</td>
				<td>{{'assoName'|i18n}}</td>
				<td>{{'AssoStatus'|i18n}}</td>
				<td>{{"Select"|i18n}}</td>
				<td>{{"Operation"|i18n}}</td>
				</tr>
		    </thead>
		    <tr v-for="c in plist" :key="'h_'+c.id">
		        <td>{{c.name}}</td>
				<td>{{c.boardType}}</td>
				<td>{{c.boardName}}</td>
				<td>{{c.type}}</td> 
				<td>{{c.status==1?'启用':'禁用'}}</td>
				<td>{{c.assoName}}</td>
				<td>{{c.assoStatus==1?'启用':'禁用'}}</td>
				<td>
					<el-checkbox-group v-model="c.selected" size="small">
					      <el-checkbox-button @change="selectDef(c)" :checked="c.selected" size="mini">{{c.selected ? "已选":"未选"}}
						  </el-checkbox-button>
					</el-checkbox-group>
				</td>
				
				<td>
					<el-button size="mini" type="primary" @click="updateLabel(c)">改名</el-button>
				</td>
				
		    </tr>
		</table>
		
		<div v-if="isLogin && plist && plist.length > 0" style="position:relative;text-align:center;">
		    <Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
		          show-elevator show-sizer show-total @on-change="curPageChange"
		          @on-page-size-change="pageSizeChange" :page-size-opts="[10, 30, 60,100]"></Page>
		</div>
		
		<div v-if="!isLogin">
		    No permission!
		</div>
		
		<div v-if="!plist || plist.length == 0">
		    No data!
		</div>
		
		<!-- 改名对话框 -->
		<el-dialog
		  title="服务标题"
		  :visible.sync="showUpdateLabel"
		  width="30%"  :modal="false"  :close-on-click-modal="false" 
		  :append-to-body="true"
		>
		  <!-- 输入框 -->
		  <el-input
			v-model="labelParam.assoName"
			placeholder="请输入服务标题"
		  ></el-input>
			
		  <!-- 底部按钮 -->
		  <span slot="footer" class="dialog-footer">
			<el-button @click="cancelUpdateLabel()">取消</el-button>
			<el-button type="primary" @click="doUpdateLabel()">确定</el-button>
		  </span>
		</el-dialog>
		
	</div>
</template>

<script>
	
	const cid = 'DeviceSrvList';

	export default {
		name: cid,
		props: {
			updateModel: {
				type: Boolean,
				default: false
			},
		},
		
		data() {
			return {
				by:this.$jm.IOT.JM_OP_SRC.PRODUCT,//1:产品， 2： 设备
				dev:{},
				product:{},
				productId:0,
				
				p: {},
				addOrUpdateDialog: false,
				model: 3,
				
				defList:[],
				selDef:{},
				
				errorMsg:'',
				isLogin:true,
				plist: [],

				queryParams:{size:30,curPage:1,ps:{selectType:0}},
				totalNum:0,
				
				dels:{},
				adds:{},
				
				oldLabelVal:null,
				showUpdateLabel:false,
				labelParam:{},
			}
		},

		methods: {
			
			serviceCheck(){
				this.$jr.rpc.invokeByCode(-1598543300, [this.dev.deviceId])
				.then((resp) => {
					if (resp.code) {
						this.$notify.error({title: '错误',message: resp.msg || "未知错误"});
					}
					this.refresh()
				}).catch((err) => {
					this.$notify.error({title: '错误',message: err});
				});
			},
			
			cancelUpdateLabel(){
				this.showUpdateLabel = false
				this.labelParam.assoName = this.oldLabelVal
			},
			
			async doUpdateLabel(){
				this.showUpdateLabel = false
				let r = await this.$jr.rpc.invokeByCode(-519816947, [this.labelParam.assoId, this.labelParam.assoName]);
				console.log("update name result: ", r);
				if(r.code != 0) {
					this.$notify.error({title: '错误',message: r.msg})
				}else{
					this.$notify.info({title: '提示',message: "更新成功"})
				}
			},
			
			updateLabel(c) {
				this.labelParam = c
				this.oldLabelVal = c.assoName
				this.showUpdateLabel=true
			},
			
			closeDevFunListDrawer(){
				this.$parent.$parent.closeDevSrvListDrawer()
			},
			
			canShow() {
				return true
			},

			changeQryType(selectType) {
				this.queryParams.ps.selectType = selectType
				this.refresh()
			},
			
			selectDef(vo) {
				console.log(vo)
				
				if(vo.selected) {
					if(!vo.assoId) {
						this.adds[vo.id] = true
					}
					delete this.dels[vo.id]
				} else {
					if(vo.assoId) {
						this.dels[vo.id] = true
					}
					delete this.adds[vo.id]
				}
				
				this.$forceUpdate()
			},
			
			doQuery() {
				this.queryParams.curPage = 1
			    this.refresh()
			},
		
			async doAddOrUpdateParam() {
				let addFuns = []
				for(let k in this.adds) {
					addFuns.push(k)
				}
			
				let delFuns = []
				for(let k in this.dels) {
					delFuns.push(k)
				}
				
				if(addFuns.length == 0 && delFuns.length == 0) {
					this.$notify.error({title: '错误',message: "无更新"})
					return;
				}
				
				console.log(addFuns,delFuns)
				//updateOrDelFuns-2122626877
				let r = await this.$jr.rpc.invokeByCode(-2122626877, [this.dev.deviceId, addFuns, delFuns])
				
				if(r.code != 0 || r.msg) {
					this.$notify.error({title: '错误',message: r.msg})
				}else{
					this.$notify.info({title: '提示',message: "更新成功"})
				}
				
				this.adds = []
				this.dels = []
				
				//console.log("After update",addFuns,delFuns)
				this.refresh()

			},
			
			curPageChange(curPage){
				this.queryParams.curPage = curPage
			    this.refresh()
			},
			
			pageSizeChange(pageSize){
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
			    this.refresh()
			},
			
			refresh() {
			    let self = this
			    this.isLogin = this.$jr.auth.isLogin()
			    if(this.isLogin) {
			        let params = this.getQueryConditions()
			        let self = this
					
					console.log(this.dev, params)
					
					//listProductFuns
			        this.$jr.rpc.invokeByCode(-1231073421, [params])
			            .then((resp)=>{
			                if(resp.code == 0){
			                    if(resp.total == 0) {
									this.plist = []
									this.totalNum = 0
			                        this.$notify.info({title: '提示',message: "查无数据"})
			                    } else {
									console.log(resp)
			                        this.plist = resp.data
			                        this.totalNum = resp.total
									if(this.totalNum) {
										this.plist.forEach(e=>{
											e.selected = !!e.assoId
										})
									}
			                    }
			                } else {
								this.plist = []
								this.totalNum = 0
			                    this.$notify.error({title: '提示',message: resp.msg})
			                }
			            }).catch((err)=>{
			            window.console.log(err)
			        });
			    } else {
			        self.roleList = []
			    }
			},
			
			getQueryConditions() {
				this.queryParams.ps.deviceId = this.dev.deviceId
			    return this.queryParams;
			},
			
			loadFunListDataByPrd(prd){
				this.by = this.$jm.IOT.JM_OP_SRC.PRODUCT
				this.plist = []
				this.totalNum = 0
				this.product = prd
				this.refresh()
			},
			
			loadDataByDev(dev){
				this.by = this.$jm.IOT.JM_OP_SRC.DEVICE
				this.dev = dev
				this.plist = []
				this.totalNum = 0
				this.refresh()
			}

		},

		async mounted () {
		    //this.refresh()
			this.plist = []
			this.totalNum = 0
		},
		
		beforeDestroy() {
		    this.$jr.auth.removeActListener(cid)
		},

	}
</script>

<style>
	.DeviceSrvList {
		border-top: 1px dotted lightgray;
		margin-top: 6px;
		padding-top: 10px;
		text-align: left;
	}
	
	.title{
		font-weight: bold;
		font-size: 17px;
	}
	
	.valCol{
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
	
	/* 对话框样式 */
	.el-dialog {
	  border-radius: 8px;
	  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	}
	
	/* 标题样式 */
	.el-dialog__title {
	  font-size: 18px;
	  font-weight: bold;
	  color: #333;
	}
	
	/* 输入框样式 */
	.el-input {
	  margin-top: 20px;
	}
	
	/* 按钮样式 */
	.dialog-footer {
	  display: flex;
	  justify-content: flex-end;
	}
</style>
